import type { InjectionKey } from 'vue'

export declare type T3CeAppearanceWhereToShow =
  | 'show-on-desktop'
  | 'show-on-mobile'
  | ''

export declare type T3CeBackgroundClass =
  | 'color-gray'
  | 'color-gradient-green'
  | 'color-gradient-blue'
  | 'color-gradient-purple'
  | 'color-light-blue'
  | 'color-light-blue-tint'
  | ''

export interface T3FrameAppearance {
  background?: string | string[]
}

export const T3FrameAppearanceKey: InjectionKey<T3FrameAppearance | undefined> = Symbol('t3frame')
